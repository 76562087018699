html, body { max-width: 100%; overflow-x: hidden; }

a, a:hover, a:visited, a:active {
  color: inherit;
  text-decoration: none;
} 

br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 8px; /* change this to whatever height you want it */
}

#logo {
  margin: auto 0px;
  padding: 0px 24px;
  display: inline-flex;
  align-items: center;
  height: 100%;
}

#pic_jim {
  position: absolute;
  z-index: -1;
  top: 102px;
  left: 2px;
  scale: 0.85;
  transform: scaleX(-1);
}

#pic_matt {
  position: absolute;
  z-index: -1;
  top: 100px;
  right: 0px;
  scale: 0.85;
}

.navbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: #15294b 0px -6px 10px;
  z-index: 100;
}

.navbarspacer {
  height: 100px;
}

.navbutton {
  display: inline-flex;
  padding: 10px 20px;
  height: 80px;
  vertical-align: bottom;
  align-items: center;
  color: black;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  background-color: white;
  border-bottom: 4px solid white;
  transition: background-color 0.2s, border-bottom 0.2s, height 1s;
}

.navbutton:hover {
  background-color: #f1f8ff;
  border-bottom: 4px solid #15294b;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdownlist {
  position: absolute;
  width: 200px;
  background-color: white;
  overflow: hidden;
  border-color: #15294b;
  border-style: solid;
  border-width: 0px 2px 0px 2px;
  transition: opacity 0.2s;
}

.dropdownbutton {
  padding: 8px 10px;
  font-family: 'Playfair Display', serif;
  font-size: 20px;
  color: black;
  background-color: white;
  border-left: 2px solid white;
  transition: background-color 0.2s, border-left 0.2s;
}

.dropdownbutton:hover {
  background-color: #f1f8ff;
  border-left: 2px solid #15294b;
}

#nav-fill {
  flex-grow: 1;
}
#nav-misc {
  display: flex;
  margin-left: auto;
  padding-right: 30px;
  padding-bottom: 8px;
  align-items: center;
  font-family: 'Playfair Display', serif;
}
#nav-misc h1 {
  margin: 0;
  font-size: 28px;
}
#nav-misc h2 {
  margin: 0;
  font-size: 20px;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
}

.image-container {
  position: absolute;
  z-index: -2;
}

.body-container {
  padding: 32px 50px;
  background-color: white;
  /*border-top: 4px solid black;*/
  /*border-bottom: 4px solid black;*/
}

.titletext {
  font-family: 'Cinzel', serif;
  font-size: 90px;
}

.bwtitletext {
  font-family: 'Cinzel', serif;
  font-size: 70px;
}

@keyframes rollout-title {
  0% { width: 0px; }
  100% { width: 840px; }
}

.title-underline {
  width: 840px;
  height: 2px;
  background-color: #ffffff45;
  border-radius: 2px;
  animation: rollout-title 1s;
}

.subtitletext {
  position: absolute;
  font-family: 'Lato', sans-serif;
  font-size: 50px;
}

.headertext {
  font-family: 'Cinzel', serif;
  font-size: 50px;
  margin: 20px 80px;
  line-height: 48px;
}

.headertext2 {
  font-family: 'Cinzel', serif;
  font-size: 40px;
  margin: 20px 0px;
  line-height: 48px;
}

.banner {
  width: 100%;
}

.gradientbanner {
  position: absolute;
  width: 100%;
  height: 250px;
  background-size: cover;
  box-shadow: inset #15294bd9 0px 0px 15px 2px;
  z-index: -2;
}

.bodytext {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin: 20px 80px;
  padding: 10px 0px;
  border-bottom: 2px solid #f0f0f0;
  border-radius: 2px;
}

.bodytext2 {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin: 20px 0px;
  padding: 10px 0px;
  border-bottom: 2px solid #f0f0f0;
  border-radius: 2px;
}

.bodytext3 {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin: 20px 24px;
  padding: 10px 0px;
  border-bottom: 2px solid #f0f0f0;
  border-radius: 2px;
}

.twocolumn {
  column-count: 2;
}

.attorney-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 50px;
  margin: 35px 0px;
  background-color: #fdfdfe;
  box-shadow: inset #15294b 0 0 10px -8px;
}

.attorney-card {
  height: 350px;
  margin: 20px;
  /*background-color: #f1f8ff;*/
  text-decoration: none;
  border: 2px solid #ccd3da;
  border-radius: 8px;
  box-shadow: #f1f8ff 0 0 0 2px;
  flex-basis: 280px;
	flex-grow: 0;
	flex-shrink: 0;
  transition: box-shadow 0.1s;
}

.card-contents {
  width: 100%;
  height: 100%;

  background-size: contain;
  background-repeat: no-repeat;

  text-decoration: none;
  color: #15294b;
  font-family: 'Playfair Display', serif;

  display: flex;
  align-items: end;
}

.attorney-card:hover {
  box-shadow: #15294b 0 0 1px 3px;
}

.footer {
  padding: 50px;
  background-color: #15294b;
  color: white;
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  box-shadow: #15294b 0px 2px 6px;
}

.footer-container {
  display: flex;
  width: 100%;
}

.footer-section {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  /*border: 2px solid white;*/
}

.addresses {
  padding: 10px 20px;
  font-size: 20px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
}

.shortcut-container {
  display: flex;
  padding: 0px 300px;
}

.shortcut {
  margin: 10px;
  padding: 10px 25px;

  background-color: #15294b;
  color: white;
  border: 2px solid white;
  border-radius: 2px;

  font-family: 'Playfair Display', serif;
  font-size: 25px;

  flex-basis: 0;
  flex-grow: 1;
  text-align: center;

  transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.shortcut:hover {
  /*background-color: #e0efff;*/
  color: #12b3f0;
  border: 2px solid #12b3f0;
}

#ataglance {
  position: absolute;
  padding: 0;
  top: 510px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ataglance-section {
  padding: 15px 15px 25px 15px;
  margin: 2px;
  height: 75px;
  background-color: #f1f8ff;
  color: black;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  text-align: center;
  flex-basis: 200px;
  
  border-radius: 2px;
  box-shadow: #15294b80 0px 0px 21px -5px;
  transition: color 0.2s;
}

/*
.ataglance-section:hover {
  color: #4987ff;
}
*/

.big {
  font-size: 40px;
}

.stat-container {
  display: flex;
  padding: 0px 100px;
}

.stat-section {
  height: 315px;
  margin: 30px;

  font-family: 'Lato', sans-serif;
  font-size: 30px;

  border-bottom: 2px solid #f0f0f0;

  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.stat-section > p {
  height: 110px;
  margin: 26px 0px 0px;
}

.stat-section > img {
  height: 100px;
  margin: 6px;
}

.stat-section a {
  height: 35px;
  color: #15294b;
  text-decoration: underline;
  transition: color 0.1s;
}

.stat-section a:hover {
  color: #12b3f0;
}

.carousel-container {
  padding: 20px 50px;
  overflow-x: scroll;
  box-shadow: inset black 0px 0px 10px -4px;
  border: 1px solid #0f1d35;
  background-color: #15294b;
}

.carousel {
  width: 2640px;
}

.carousel-section {
  display: inline-block;
  width: 286px;
  height: 340px;
  margin: 10px;
  padding: 10px;
  color: white;

  vertical-align: top;

  font-family: 'Lato', sans-serif;
  font-size: 30px;

  border: 2px solid #f0f0f0;
  border-radius: 8px;
  box-shadow: black 0 0 21px -5px;

  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.carousel-section > h1 {
  display: block;
  font-size: 50px;
  font-weight: bold;
  padding: 20px 0px;
  margin: 0 0 15px;
  background-color: #dff9d9;
  color: #375130;
  box-shadow: #375130 0 2px 0 0;
  font-family: 'Playfair Display';
}

.carousel-section b {
  font-size: 30px;
}

.carousel-section > div {
  font-size: 20px;
}

.flex-container {
  display: flex;
  padding: 0px 100px;
}

.flex-section {
  flex-basis: 0;
  flex-grow: 1;
}

.practice-container {
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 75px;
}

.practice-section {
  margin: 10px;
  padding: 40px;
  color: white;
  border: 2px solid white;
  border-radius: 8px;
  height: 75px;

  font-family: 'Lato', sans-serif;
  font-size: 30px;

  background-size: cover;

  box-shadow: black 0 0 21px -5px;

  flex-basis: 36%;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: color 0.2s, border 0.2s;
}

.practice-section:hover {
  color: #12b3f0;
  border: 2px solid #12b3f0;
}

.panel {
  width: calc(100% - 450px);
  margin: auto;
  background-color: #15294b;
  box-shadow: #15294b 0px 0px 10px 0px;
}

.relative-container {
  position: relative;
}

#floattext {
  background-color: #f1f8ff;
  color: black;
  position: absolute;
  top: 100px;
  left: 160px;
  width: 500px;
  padding: 20px 50px;
}

#floatimage {
  scale: 0.7;
  position: absolute;
  top: 18px;
  left: 600px;
  scale: 0.8;
}

.float {
  /*border: 3px solid #d2deeb;*/
  border-radius: 2px;
  box-shadow: #15294b80 0px 0px 21px -5px;
}

.latobox {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}

.testimonial {
  position: absolute;
  width: 1200px;
  min-height: 120px;
  padding: 20px;
  overflow-y: hidden;
  font-family: 'Lato', sans-serif;
  font-size: 32px;
  font-weight: bold;
  background-color: white;
  border: 2px solid #f1f8ff;
  border-radius: 2px;

  color: #ddddee;
  margin-top: 0px;
  z-index: 1;
  transition: margin-top 0.15s, color 0.2s;
}

.testimonial:hover {
  color: black;
  margin-top: -10px;
  z-index: 2;
}

#testimonial-button {
  width: 100px;
  margin: 20px 200px;
  color: #15294b;
  
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  text-align: center;
  text-decoration: underline;

  transition: color 0.2s;
}

#testimonial-button:hover {
  color: #12b3f0;
}

.contact-form-bg {
  width: 100%;
  height: 750px;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-form {
  height: 750px;
  margin: 100px;
  margin-top: 0;
  background-color: #15294bc0;
}

.form-boxes {
  margin: 155px 70px;
}

.form-label {
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}

.form-box {
  width: 95%;
  height: 30px;
  padding: 8px;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
}

#contactmethod {
  margin-left: 10px;
  width: 30%;
  height: 30px;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: bold;
}

#formsubmit {
  width: 150px;
  height: 30px;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  margin: auto;
  display: block;
}

.socialmedialink {
  margin: 12px;
  margin-left: 20px;
}
.socialmedialink > img {
  transform: scale(0.18);
  margin: -70px -50px -70px -70px;
}
.socialmedialink > a {
  display: inline-block;
  width: 90px;
}

.aboutpage {
  margin: 10px 80px;
}

.attorney-name {
  font-size: 25px;
  padding: 19px 14px;
  font-weight: bold;
}

.attorney-carousel {
  display: flex;
  width: 2000px;
  height: 278px;
  justify-content: center;
  align-items: center;
  border: 2px solid #f0f0f0;
  padding: 20px 20px;
  background-color: #fdfdfe;
  box-shadow: inset #15294b 0 0 10px -8px;
}

.attorney-carousel-box {
  width: 260px;
  height: 260px;
  margin: 5px;
  background-size: contain;
  border: 2px solid #f1f8ff;
  border-radius: 2px;
  transition: width 0.1s, height 0.2s;
}

.attorney-carousel-box:hover {
  width: 264px;
  height: 264px;
}

.attorney-carousel-button {
  position: relative;
  width: 360px;
  margin: 20px auto;
  margin-top: -45px;
  box-shadow: #15294b 0 0 21px -5px;
  z-index: 1;
}
/*Maybe try using opacity gradient so that the attorney carousel just fades to white at the ends of the screen?*/

.attorney-portrait {
  width: 490px;
  margin: 32px;
  border: 2px solid #15294b;
  border-radius: 2px;
  box-shadow: #15294b80 0px 0px 21px -5px;
}

.attorney-title {
  margin: 32px 50px 0px 0px;
  font-family: 'Playfair Display', serif;
  font-size: 50px;
  font-weight: bold;
  border-top: 2px solid #f0f0f0;
}

.attorney-subtitle {
  font-family: 'Lato', sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-right: 50px;
  padding: 5px 0px;
  border-bottom: 2px solid #f0f0f0;
  border-radius: 2px;
}

#attorney-background {
  padding: 40px 50px;
  background-size: cover;
}

.attorney-text {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin: 30px 60px 20px 0px;
}

.attorney-header {
  margin: 35px 0 0 0;
  font-family: 'Lato', sans-serif;
  font-size: 30px;
  font-weight: bold;
}

.attorney-specialties {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin: 4px 60px 20px 0px;
}

.pa-navbar {
  width: 100%;
  background-color: #15294b;
  box-shadow: black 0 0 21px -10px;

  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 30px;
}

.pa-navbar > a {
  display: block;
  padding: 20px;
  border-left: 4px solid #15294b;
  box-shadow: inset #15294b 0 0 0 100px;
  background-size: cover;
  transition: color 0.1s, border 0.1s, box-shadow 0.1s;
}

.pa-navbar > a:hover {
  color: #12b3f0;
  box-shadow: inset #15294b80 0 0 0 100px;
  border-left: 4px solid #12b3f0;
}

/* FAQ Page Template */
.faq-text {
  padding: 32px 125px 64px 125px;
}
.faq-text a {
  color: blue;
  text-decoration: underline;
}
.faq-text > p {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin: 0 60px 5px 0;
  padding: 0 0 10px 0;
}
.faq-text > h1 {
  position: relative;
  font-family: 'Lato', sans-serif;
  font-size: 40px;
  font-weight: bolder;
  margin: 15px 60px 15px 0;
  padding-bottom: 5px;
  border-radius: 2px;
}
.faq-text nav {
  position: absolute;
  top: -110px;
}
.faq-navbar {
  width: 400px;
  font-family: 'Lato', sans-serif;
  box-shadow: black 0 0 21px -10px;
  margin-bottom: 30px;
}
.faq-navbar > a {
  color: white;
  display: block;
  padding: 10px;
  text-decoration: none;
  border-left: 4px solid #15294b;
  background-color:#15294b;
  transition: color 0.1s, border 0.1s;
}
.faq-navbar > a:hover {
  color: #12b3f0;
  border-left: 4px solid #12b3f0;
}

/* Practice Area Page Template */
.practice-area-text > p {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin: 0 60px 5px 0;
  padding: 0 0 10px 0;
}
.practice-area-text > h1 {
  font-family: 'Lato', sans-serif;
  font-size: 50px;
  font-weight: bolder;
  margin: 15px 60px 15px 0;
  padding-bottom: 5px;
  border-bottom: 2px solid #f0f0f0;
  border-radius: 2px;
}
.practice-area-text > h2 {
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  color: #2c559b;
  margin: 5px 0 15px;
}
.practice-area-text > h3 {
  font-family: 'Playfair Display', serif;
  font-size: 22px;
  font-style: italic;
  color: #4b75be;
  margin: 10px 0 10px;
}
.practice-area-text b {
  /*color: #0d9ed3;*/
  color: #c88a3d;
}

/* Case Results */
.caseresults-summary {
  display: flex;
  height: 242px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px solid #f0f0f0;
  border-radius: 2px;
}
.caseresults-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 100px 50px 100px;
}
.caseresult {
  margin: 2px;
  width: 300px;
  height: 300px;
  border: 2px solid white;
  border-radius: 2px;
  box-shadow: gray 0px 0px 12px -8px;
  font-family: 'Lato', sans-serif;
  text-align: center;
  transition: border 0.2s;
}
.caseresult:hover {
  border: 2px solid #12b3f0;
  cursor: pointer;
}
.caseresult-amount {
  margin: 50px 10px;
  font-size: 50px;
  font-weight: bold;
}
.caseresult-divider {
  width: 50%;
  height: 2px;
  border-radius: 1px;
  background-color: black;
  margin: auto;
}
.caseresult-text {
  margin: 50px 5px;
  font-size: 30px;
}

.testimonial-bg {
  background-image: url('images/bg_darkblue.jpeg');
  background-repeat: repeat;
  padding-top: 1px;
  border-bottom: 8px solid black;
}
.testimonial-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 50px 50px 50px;
}
.testimonial-card {
  margin: 20px;
  padding: 12px;
  background-color: white;
  justify-content: center;
  width: 560px;
  height: 440px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 32px;
  border-radius: 2px;
  border: 2px solid #d0d6db;
  box-shadow: black 0 0 21px -5px;
}
.testimonial-card > h1 {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 32px;
}
.testimonial-card > p {
  margin-top: 8px;
  color: #737380;
  font-size: 16px;
  /*font-weight: normal;*/
}
.review-container {
  padding: 20px;
  padding-bottom: 150px;
}

.pagebar {
  display: flex;
  justify-content: center;
  height: 50px;
  margin-bottom: 0px;
}
#page-left, #page-number, #page-right {
  margin: 10px;
  padding: 10px;
  font-size: 30px;
  font-weight: bold;
}
#page-left, #page-right {
  color: black;
  cursor: pointer;
}
#page-left:hover, #page-right:hover {
  color: #12b3f0;
  cursor: pointer;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 50px 0px 50px;
}
.contact-card {
  display: contents;
  cursor: pointer;
}
.contact-card > img {
  transform: scale(0.5);
  filter: invert(1);
}
.contact-info {
  border: none;
  text-align: center;
  line-height: 27px;
}
.contact-info br {
  margin-top: 0;
}
.contact-header {
  margin: 0 50px;
  font-family: 'Lato', sans-serif;
  font-size: 40px;
  font-weight: bold;
}